import './App.css';

import {Container} from "react-bootstrap";

import banner from './res/SafeWalkBanner.jpg';


function App() {
    return (
        <Container className="d-flex flex-column pt-3" style={{height: "100vh"}}>
            <div className="main">
                <div className="d-flex justify-content-center">
                    <img src={banner} className='banner' alt="Banner"/>
                </div>
                <p className="display-4 text-center mt-1">
                    This pilot has now ended.
                </p>
                <p className="display-6 text-center">
                    Contact <a href="mailto:leaguesupport@efcl.org">leaguesupport@efcl.org</a> with any inquiries.
                </p>
            </div>
        </Container>
    );
}

export default App;
